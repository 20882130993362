:root {
  --purple: #584D7C;
  --light-purple: #735D9F;
  --very-light-purple: #f0ebff;
}

.App {
  text-align: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f0f2f5;
  font-family: -applesystem, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
  background: linear-gradient(135deg, var(--very-light-purple), #fff);
  min-height: 100vh;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #333;
}

.container {
  background: white;
  border-radius: 8px;
  padding: 2rem;
  margin: 2rem;
  max-width: 800px;
  width: 90%;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}


.logo-container {
  margin-top: 0px;
  background: rgba(255,255,255,0.8);
  border-radius: 8px;
  align-items: center;
  gap: 1rem;
  max-width: 800px;
  background-image: url('../public/Crayon_logo.png');
  background-size: contain;
  background-repeat: no-repeat;
}

.logo {
  width: 100px;
  height: 0px;
}

.logo-text {
  display: flex;
  flex-direction: column;
}

.logo-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
}

.logo-subtitle {
  color: #666;
  margin: 0;
}

.progress-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2rem 0;
}

.progress-line {
  flex-grow: 1;
  height: 4px;
  background: var(--purple);
  margin: 0 0px;
}

.progress-dot {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: var(--purple);
}

.progress-dot.empty {
  background: white;
  border: 2px solid var(--purple);
}

.text-area {
  width: 95%;
  min-height: 150px;
  border: 2px solid #ddd;
  border-radius: 4px;
  padding: 1rem;
  margin: 1rem 0;
  font-size: 1rem;
  resize: vertical;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}

.inspiration {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #666;
  text-decoration: none;
}

.inspiration:hover {
  color: var(--purple);
}

.word-count {
  color: #666;
}

.next-button {
  background: var(--purple);
  color: white;
  border: none;
  padding: 0.75rem 2rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: opacity 0.3s ease;
}

.next-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.next-button:not(:disabled):hover {
  background: var(--light-purple);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.responsive-container {
  flex-direction: row;
}

@media (max-width: 768px) {
  .responsive-container {
    flex-direction: column;
    height: auto;
  }
}
